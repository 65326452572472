<template>
    <div
        v-if="environment === 'develop'"
        class="bg-danger-600 text-white w-full z-50 flex justify-center relative font-bold">
        <div>
            Entwicklungssystem - Anträge bitte auf
            <a
                class="underline hover:text-gray-200"
                href="https://juleica-antrag.de">juleica-antrag.de</a>
            stellen
        </div>
    </div>
</template>

<script setup>

const config = useRuntimeConfig()

const environment = computed(() => {
    if (config.environment) {
        return config.environment
    } else if (config.public && config.public.environment) {
        return config.public.environment
    } else {
        return "production"
    }
})

</script>